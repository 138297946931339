.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  background-position: center;
  background-size: cover;

  margin-right: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-width: 240px; /* Ancho mínimo para evitar colapso */
  :hover {
    background: #00aaff;
  }
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card {
  border: 2px solid #e0e0e0;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s ease;
  width: 100%;
  margin: 0 auto;
  height: 550px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.card__content {
  width: 100%;
  flex-direction: column;
  border-radius: 1rem;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 240px;
}

.card__title {
  color: #000;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.5;
  border-radius: 0.5rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.card__share-button {
  background: rgb(0, 170, 255);
  color: rgb(250, 250, 250);
  border-radius: 2.5rem;
  font-size: 1.5rem;
  padding: 1rem 1.5rem;
  text-decoration: none;
  transition: all 400ms ease-in-out 0s;
  line-height: 1.5;
  border: 2px solid rgb(0, 170, 255);
  cursor: pointer;
  display: block;
  margin-top: 10px;
  text-align: center;
  width: 100%;
}
