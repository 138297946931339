@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
  url('./assets/theme/Fonts/Poppins-ExtraLight.ttf') format("truetype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
  url('./assets/theme/Fonts/Poppins-ExtraLightItalic.ttf') format("truetype");
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
  url('./assets/theme/Fonts/Poppins-ExtraLight.ttf') format("truetype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
  url('./assets/theme/Fonts/Poppins-ExtraLightItalic.ttf') format("truetype");
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
  url('./assets/theme/Fonts/Poppins-Light.ttf') format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
  url('./assets/theme/Fonts/Poppins-LightItalic.ttf') format("truetype");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
  url('./assets/theme/Fonts/Poppins-Regular.ttf') format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
  url('./assets/theme/Fonts/Poppins-Italic.ttf') format("truetype");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
  url('./assets/theme/Fonts/Poppins-Medium.ttf') format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
  url('./assets/theme/Fonts/Poppins-MediumItalic.ttf') format("truetype");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
  url('./assets/theme/Fonts/Poppins-SemiBold.ttf') format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
  url('./assets/theme/Fonts/Poppins-SemiBoldItalic.ttf') format("truetype");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
  url('./assets/theme/Fonts/Poppins-Bold.ttf') format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
  url('./assets/theme/Fonts/Poppins-BoldItalic.ttf') format("truetype");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
  url('./assets/theme/Fonts/Poppins-ExtraBold.ttf') format("truetype");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
  url('./assets/theme/Fonts/Poppins-ExtraBoldItalic.ttf') format("truetype");
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
  url('./assets/theme/Fonts/Poppins-Black.ttf') format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
  url('./assets/theme/Fonts/Poppins-BlackItalic.ttf') format("truetype");
  font-weight: 900;
  font-style: italic;
}

body {
  margin: 0;
  font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
